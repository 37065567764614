<template>
  <v-dialog v-model="modal" no-click-animation persistent width="290px" :disabled="readonly">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-bind="attrs"
        v-on="on"
        ref="datePicker"
        v-model="readableDate"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        :dense="dense"
        :hide-details="hideDetails"
        :outlined="outlined"
        :hint="hint"
        :persistent-hint="persistentHint"
        :rules="rules"
        :required="required"
        :error="error"
        @click="resetValidation"
        :data-cy="`date-picker-input-${label}`"
      >
      </v-text-field>
    </template>

    <v-date-picker
      v-model="selectDate"
      scrollable
      format="ampm"
      :min="min"
      :max="max"
      @change="$emit('change', $event)"
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
      <v-btn text color="primary" :disabled="selectDate.length != 10" @click="saveDate()"> OK </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
export default {
  name: 'DatePicker',
  props: [
    'value',
    'label',
    'readonly',
    'hint',
    'persistentHint',
    'rules',
    'required',
    'min',
    'max',
    'dense',
    'hideDetails',
    'error',
    'outlined',
  ],
  data() {
    return {
      modal: false,
      selectDate: '',
      showDate: '',
    };
  },
  computed: {
    readableDate() {
      if (!this.value) return '';
      const date = !isNaN(Date.parse(`${this.value}T00:00:00`))
        ? new Date(`${this.value}T00:00:00`)
        : new Date(`${this.value}`);

      return date.toLocaleDateString('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
  },
  methods: {
    saveDate() {
      this.showDate = this.selectDate;
      this.$emit('input', this.selectDate);
      this.validate();
      this.modal = false;
      this.$nextTick(() => {
        this.validate();
      });
    },
    validate() {
      const isValid = this.$refs['datePicker'].validate(true);
      return isValid;
    },
    resetValidation() {
      this.$refs['datePicker'].resetValidation();
    },
  },
  watch: {
    value() {
      this.showDate = this.value;
    },
  },
};
</script>
