var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    { attrs: { readonly: !_vm.canEditSettings } },
    [
      _c(
        "v-row",
        [
          _c("v-spacer"),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _vm.canEditSettings
                ? _c(
                    "v-btn",
                    {
                      staticClass: "nav-button success white--text mb-4",
                      attrs: { text: "" },
                      on: { click: _vm.save },
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "white--text", attrs: { left: "" } },
                        [_vm._v("mdi-content-save")]
                      ),
                      _vm._v(" Save "),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "px-2", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4" } },
            [
              _c("v-subheader", [
                _vm._v("Display Additional Invoice Costs on Invoice"),
              ]),
              _c(
                "v-radio-group",
                {
                  attrs: { row: "" },
                  model: {
                    value: _vm.displayAdditionalInvoiceCostsInInvoice,
                    callback: function ($$v) {
                      _vm.displayAdditionalInvoiceCostsInInvoice = $$v
                    },
                    expression: "displayAdditionalInvoiceCostsInInvoice",
                  },
                },
                [
                  _c("v-radio", { attrs: { label: "Yes", value: true } }),
                  _c("v-radio", { attrs: { label: "No", value: false } }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }