var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    [
      _c(
        "v-list-item-group",
        _vm._l(_vm.files, function (item, i) {
          return _c(
            "v-list-item",
            { key: i },
            [
              _c("v-list-item-icon", [_c("v-icon", [_vm._v("mdi-file")])], 1),
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", { staticClass: "ml-5" }, [
                    _vm._v(_vm._s(item.filename)),
                  ]),
                ],
                1
              ),
              _c("v-list-item-action", [
                _c(
                  "a",
                  {
                    attrs: {
                      target: "_blank",
                      href: `/${_vm.client}/attachment/${
                        item.id
                      }/${encodeURIComponent(item.filename)}`,
                    },
                  },
                  [
                    _c("v-icon", { attrs: { color: "primary" } }, [
                      _vm._v("mdi-download"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "v-list-item-action",
                [
                  _c(
                    "v-icon",
                    {
                      attrs: { color: "error" },
                      on: {
                        click: function ($event) {
                          return _vm.deleteAttachment(item)
                        },
                      },
                    },
                    [_vm._v("mdi-delete")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }