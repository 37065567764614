var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-expansion-panels",
        { attrs: { mandatory: "" } },
        [
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [
                _c("div", { staticClass: "header-text" }, [_vm._v("Settings")]),
              ]),
              _c(
                "v-expansion-panel-content",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _vm.canEditSettings
                            ? _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "nav-button success white--text mb-4",
                                  attrs: { text: "" },
                                  on: { click: _vm.save },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "white--text",
                                      attrs: { left: "" },
                                    },
                                    [_vm._v("mdi-content-save")]
                                  ),
                                  _vm._v(" Save "),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-form",
                    { attrs: { readonly: !_vm.canEditSettings } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "px-2", attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "bg-blue",
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-subheader", [
                                _vm._v("Display Trip Estimator?"),
                              ]),
                              _c(
                                "v-radio-group",
                                {
                                  attrs: { row: "" },
                                  model: {
                                    value: _vm.tripEstimate.display,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tripEstimate, "display", $$v)
                                    },
                                    expression: "tripEstimate.display",
                                  },
                                },
                                [
                                  _c("v-radio", {
                                    attrs: { label: "Yes", value: true },
                                  }),
                                  _c("v-radio", {
                                    attrs: { label: "No", value: false },
                                  }),
                                ],
                                1
                              ),
                              _c("v-subheader", [
                                _vm._v(
                                  "Include Additional Invoice Cost on Trip Estimate?"
                                ),
                              ]),
                              _c(
                                "v-radio-group",
                                {
                                  attrs: { row: "" },
                                  model: {
                                    value:
                                      _vm.tripEstimate
                                        .showAdditionalInvoiceCost,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.tripEstimate,
                                        "showAdditionalInvoiceCost",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "tripEstimate.showAdditionalInvoiceCost",
                                  },
                                },
                                [
                                  _c("v-radio", {
                                    attrs: { label: "Yes", value: true },
                                  }),
                                  _c("v-radio", {
                                    attrs: { label: "No", value: false },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-4",
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label:
                                    "Amount used per substitute teacher for estimating trip cost",
                                  type: "number",
                                  min: "0",
                                  outlined: "",
                                  "prepend-inner-icon": "mdi-currency-usd",
                                },
                                model: {
                                  value: _vm.tripEstimate.substituteCost,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.tripEstimate,
                                      "substituteCost",
                                      $$v
                                    )
                                  },
                                  expression: "tripEstimate.substituteCost",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "bg-blue",
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-subheader", [
                                _vm._v(
                                  "Select the rate to be used as the default on the Trip Estimator"
                                ),
                              ]),
                              _c(
                                "v-radio-group",
                                {
                                  attrs: { row: "" },
                                  model: {
                                    value: _vm.tripEstimate.defaultRate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.tripEstimate,
                                        "defaultRate",
                                        $$v
                                      )
                                    },
                                    expression: "tripEstimate.defaultRate",
                                  },
                                },
                                _vm._l(
                                  _vm.districtWideRates,
                                  function (dwr, i) {
                                    return _c("v-radio", {
                                      key: i,
                                      attrs: { label: dwr.name, value: dwr.id },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [
                _c("div", { staticClass: "header-text" }, [
                  _vm._v("District-Wide Rates"),
                ]),
              ]),
              _c(
                "v-expansion-panel-content",
                [
                  _c("district-wide-rates", {
                    on: {
                      formChanges: (value) =>
                        _vm.controlNavigation(value, "districtWideRates"),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [
                _c("div", { staticClass: "header-text" }, [
                  _vm._v("Fuel Prices"),
                ]),
              ]),
              _c(
                "v-expansion-panel-content",
                [
                  _c("fuel-prices", {
                    on: {
                      formChanges: (value) =>
                        _vm.controlNavigation(value, "fuelPrices"),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          false
            ? _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [
                    _c("div", { staticClass: "header-text" }, [
                      _vm._v("Additional Invoice Costs"),
                    ]),
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [_c("additional-invoice-costs")],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [
                _c("div", { staticClass: "header-text" }, [
                  _vm._v("Trip Estimate Template"),
                ]),
              ]),
              _c(
                "v-expansion-panel-content",
                [
                  _c(
                    "v-row",
                    [
                      !_vm.hasTemplate
                        ? _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("upload-file", {
                                attrs: {
                                  recordType: "tripestimate",
                                  recordId: 1,
                                  showSize: "",
                                },
                                on: { onUpload: _vm.onUpload },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("attachments", {
                            ref: "attachments",
                            attrs: { recordType: "tripestimate", recordId: 1 },
                            on: {
                              onFiles: _vm.handleOnFile,
                              onFileDelete: _vm.refreshStore,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }