var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    { attrs: { readonly: !_vm.canEditSettings } },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c("v-spacer"),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              !_vm.editedDWR && _vm.canEditSettings
                ? _c(
                    "v-btn",
                    {
                      attrs: { fab: "", small: "", dark: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.addDWR()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-plus")])],
                    1
                  )
                : _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.editedDWR,
                          expression: "editedDWR",
                        },
                      ],
                      attrs: { fab: "", small: "", dark: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.addDWR()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
              _c(
                "v-scroll-x-transition",
                [
                  _vm.canEditSettings
                    ? _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.selected.length,
                              expression: "selected.length",
                            },
                          ],
                          staticClass: "mx-2",
                          attrs: { dark: "", color: "red" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteDWR()
                            },
                          },
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-delete")]),
                          _vm._v(
                            "Delete " +
                              _vm._s(_vm.selected.length) +
                              " District-Wide Rate" +
                              _vm._s(_vm.selected.length > 1 ? "s" : "") +
                              " "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.editedDWR
        ? _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "12" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      outlined: "",
                                      label: "Name",
                                      value: _vm.editedDWR.name,
                                      required: "",
                                      "error-descriptions": _vm.handleErrors(
                                        _vm.$v.form.name
                                      ),
                                    },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.$v.form.name.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.$v.form.name.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.form.name,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression: "$v.form.name.$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      value: _vm.editedDWR.rate,
                                      label: "Rate Per Hour",
                                      type: "number",
                                      min: 0,
                                      outlined: "",
                                      "prepend-inner-icon": "mdi-currency-usd",
                                    },
                                    model: {
                                      value: _vm.$v.form.rate.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.form.rate,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression: "$v.form.rate.$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      value: _vm.editedDWR.otRate,
                                      label: "OT Rate Per Hour",
                                      type: "number",
                                      min: 0,
                                      outlined: "",
                                      "prepend-inner-icon": "mdi-currency-usd",
                                    },
                                    model: {
                                      value: _vm.$v.form.otRate.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.form.otRate,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression: "$v.form.otRate.$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      value: _vm.editedDWR.otherRate,
                                      label: "Other Rate Per Hour",
                                      type: "number",
                                      min: 0,
                                      outlined: "",
                                      "prepend-inner-icon": "mdi-currency-usd",
                                    },
                                    model: {
                                      value: _vm.$v.form.otherRate.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.form.otherRate,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression: "$v.form.otherRate.$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _vm.canEditSettings
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "save-btn",
                                          attrs: { color: "green", dark: "" },
                                          on: { click: _vm.save },
                                        },
                                        [_vm._v(" save ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "12" } },
            [
              _c("v-data-table", {
                attrs: {
                  "fixed-header": "",
                  headers: _vm.headers,
                  items: _vm.districtWideRates,
                  "item-key": "id",
                  "show-select": "",
                },
                on: {
                  "click:row": function ($event) {
                    return _vm.editTTM($event)
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.data-table-select`,
                      fn: function ({ isSelected, select }) {
                        return [
                          _c("v-simple-checkbox", {
                            directives: [
                              { name: "ripple", rawName: "v-ripple" },
                            ],
                            attrs: { value: isSelected },
                            on: {
                              input: function ($event) {
                                return select($event)
                              },
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.selected,
                  callback: function ($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }